.sample {
  background: repeating-linear-gradient(
    45deg,
    #5d9634,
    #5d9634 10px,
    #538c2b 10px,
    #538c2b 20px
  );
  display: flex;
  font-size: 36px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.horizontal {
  margin: 8px auto 8px auto;
  min-width: 300px;
  max-width: 970px;
  height: 250px;
  min-height: 90px;
  max-height: 250px;
}

@media (max-width: 359px) {
  .horizontal {
    min-width: 300px;
    max-width: 300px;
    min-height: 50px;
    max-height: 250px;
  }
}

@media (min-width: 360px) and (max-width: 767px) {
  .horizontal {
    min-width: 300px;
    max-width: 336px;
    min-height: 50px;
    max-height: 280px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .horizontal {
    max-width: 728px;
    max-height: 90px;
  }
}

.vertical {
  margin: 8px auto 8px auto;

  width: 300px;
  height: 600px;

  &.sidebar_2 {
    max-height: 250px;
  }

  &.sidebar_3 {
    position: sticky;
    align-self: flex-start;
    top: 0;
  }

  &.sidebar_4 {
    max-height: 250px;
  }
}

.rectangle {
  margin: 8px auto 8px auto;

  min-width: 300px;
  max-width: 336px;
  min-height: 250px;
  max-height: 280px;
}

.responsive {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  align-items: center;

  & > * {
    margin: .5rem 0 0.5rem 0;
  }

  ul, ol, div, table {
    max-width: 500px;
  }

  > .picture img {
    margin: 0;
  }
}

#waldo-tag-7388, #waldo-tag-7387 {
  max-width: 336px;
  max-height: 280px;
}

.ezoic:not(:has(.ezoic-ad, .adsbygoogle)) {
  display: none;
}

.ezoic.vertical.sticky {
  max-height: unset;
  flex: 1;
}

.ezoic.vertical {
  min-height: 264px;
  height: fit-content;
}

.unblock {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(255,255,225,0) 70vh, rgba(255,255,255,1) 75vh, rgba(159,166,178,1));
  color: #0063B1;

  .message {
    position: sticky;
    top: 82vh;

  }

  h2 {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.offer {
  max-width: 900px;
  margin: 0 auto;
}
