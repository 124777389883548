$font-family-base: 'Open Sans', Sans-Serif;
$font-weight-base: 300;
$font-size-base: 1.125rem;

$fluent-colors:
  (
    "primary":   #0078d7,
  );
$link-decoration: none;
$link-color: #0069ba;

$h1-font-size:  $font-size-base * 2.25 / 1.125!default;
$h2-font-size:  $font-size-base * 2 / 1.125 !default;
$h3-font-size:  $font-size-base * 1.75 / 1.125 !default;
$h4-font-size:  $font-size-base * 1.5 / 1.125 !default;
$h5-font-size:  $font-size-base * 1.25 / 1.125 !default;
$h6-font-size:  $font-size-base !default;

// Boostrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
// @import "bootstrap/code";
@import "bootstrap/grid";
// @import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/input-group";
// @import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
// @import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
// @import "bootstrap/badge";
// @import "bootstrap/jumbotron";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
// @import "bootstrap/toasts";
@import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/spinners";
// @import "bootstrap/utilities";
// @import "bootstrap/print";
@import "bootstrap/utilities/screenreaders";

// Fluent
@import "fluent/variables";
@import "fluent/mixins";
@import "fluent/base";
@import "fluent/colors";
@import "fluent/buttons";
@import "fluent/forms";
@import "fluent/navbar";
@import "fluent/modals";
// @import "fluent/tabs";
// @import "fluent/progress";
// @import "fluent/badges";
// @import "fluent/carousel";
@import "fluent/list-group";
@import "fluent/cards";
@import "fluent/typography";
@import "fluent/footer";
// @import "fluent/tooltip";
// @import "fluent/breadcrumb";
@import "fluent/dropdown";
// @import "fluent/alert";
// @import "fluent/popover";
// @import "fluent/tables";
// @import "fluent/welcome";
@import "fluent/pagination";

// Custom
@import "ads";
@import "article";
@import "layout";
@import "lists";
@import "nav";
@import "search";

.card {
  .card-body {
    .card-title {
      font-size: 1.25rem;
    }

    .card-text {
      font-size: 1.125rem;
    }

    .btn {
      margin: 0.5rem 0;
    }
  }
}
