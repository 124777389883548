body {
  &.modal-open {
    overflow: auto;
    padding-right: 0 !important;
  }
  &.scrollable {
    overflow-y: auto;
  }
}

.modal {
  padding-right: 0 !important;
  .modal-title {
    padding: 8px 0 5px;
  }
  .modal-dialog {
    width: auto;
    &.modal-top {
      top: 0;
    }
    &.modal-left {
      left: 0;
    }
    &.modal-right {
      right: 0;
    }
    &.modal-bottom {
      bottom: 0;
    }
    &.modal-top-left {
      top: 10px;
      left: 10px;
    }
    &.modal-top-right {
      top: 10px;
      right: 10px;
    }
    &.modal-bottom-left {
      bottom: 10px;
      left: 10px;
    }
    &.modal-bottom-right {
      bottom: 10px;
      right: 10px;
    }
  }
  .modal-content {
    border: 1px solid #0067B8;
    border-radius: 0;
    width: 100%;
  }
  .modal-header {
    border-bottom: 0;
    padding: 14px 24px;
  }
  .modal-title {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
  .modal-body {
    padding: 10px 24px;
  }
  .modal-footer {
    border-top: 0;
    padding-top: 0 24px 24px 24px;
  }
  .modal-fluid {
    width: 100%;
    max-width: 100%;
  }
  .modal-backdrop {
    background: $white-base;
    &.show {
      opacity: 0.8;
    }
  }
  .modal-side {
    position: absolute;
    bottom: 10px;
    right: 10px;
    margin: 0;
    width: 400px;
  }
  .modal-frame {
    position: absolute;
    margin: 0;
    width: 100%;
    max-width: 100%;
    &.modal-bottom {
      bottom: 0;
    }
  }
}

.modal-full-height {
  position: absolute;
  display: flex;
  margin: 0;
  width: 400px;
  height: 100%;
  top: 0;
  right: 0;
  &.modal-top,
  &.modal-bottom {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &.modal-top {
    bottom: auto;
  }
  &.modal-bottom {
    top: auto;
  }
  .modal-content {
    width: 100%;
  }
  &.modal-lg {
    width: 90%;
    max-width: 90%;
    @media (min-width: 992px) {
      width: 800px;
      max-width: 800px;
    }
    @media (min-width: 1200px) {
      width: 1000px;
      max-width: 1000px;
    }
  }
}
