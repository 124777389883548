.advanced-search-query {
  display: flex;

  input[type="text"] {
    flex: 1 1 auto;
  }
}

.advanced-search-filters {
  display: flex;

  div {
    display: flex;

    select {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  input {
    flex: 1 1 auto;
    margin-right: 6px;
  }
}