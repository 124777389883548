@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
      url('#{$open-font-path}OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-Italic.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-Italic.woff') format('woff'),
      url('#{$open-font-path}OpenSans-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url('#{$open-font-path}OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-Regular.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-Regular.woff') format('woff'),
      url('#{$open-font-path}OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
      url('#{$open-font-path}OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-BoldItalic.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-BoldItalic.woff') format('woff'),
      url('#{$open-font-path}OpenSans-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
      url('#{$open-font-path}OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-SemiBold.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-SemiBold.woff') format('woff'),
      url('#{$open-font-path}OpenSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-ExtraBoldItalic.eot');
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
      url('#{$open-font-path}OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-ExtraBoldItalic.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-ExtraBoldItalic.woff') format('woff'),
      url('#{$open-font-path}OpenSans-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
      url('#{$open-font-path}OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-LightItalic.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-LightItalic.woff') format('woff'),
      url('#{$open-font-path}OpenSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url('#{$open-font-path}OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-Bold.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-Bold.woff') format('woff'),
      url('#{$open-font-path}OpenSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-SemiBoldItalic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
      url('#{$open-font-path}OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-SemiBoldItalic.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-SemiBoldItalic.woff') format('woff'),
      url('#{$open-font-path}OpenSans-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-ExtraBold.eot');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
      url('#{$open-font-path}OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-ExtraBold.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-ExtraBold.woff') format('woff'),
      url('#{$open-font-path}OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('#{$open-font-path}OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
      url('#{$open-font-path}OpenSans-Light.eot?#iefix') format('embedded-opentype'),
      url('#{$open-font-path}OpenSans-Light.woff2') format('woff2'),
      url('#{$open-font-path}OpenSans-Light.woff') format('woff'),
      url('#{$open-font-path}OpenSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
