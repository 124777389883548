.navbar {
  font-size: 24px;
  font-weight: 300;
  width: 100%;
  width: 100vw;

  .container {
    width: 100%;
    max-width: 1302px;
    margin-left: auto;
    margin-right: auto;

    .search-form {
      order: 5;
      max-width: 200px;
      margin-left: auto;
      margin-right: 12px;
    }

    .navbar-collapse {
      .search-form {
        display: none;
      }
    }
  }
}

@media (min-width: 360px) and (max-width: 1023px) {
  .navbar > .container > .search-form {
    order: 2;
  }
}

@media (max-width: 359px) {
  .navbar > .container > .search-form {
    display: none;
  }

  .navbar > .container > .navbar-collapse > .search-form {
    display: inline-block;
    width: 100%;
    padding-left: 1rem;
    margin-top: 1rem;
  }
}

.dropdown {
  .dropdown-toggle {
    color: $white-base !important;
  }

  &.show {
    .dropdown-toggle {
      color: #212529 !important;
      background-color: $white-base;  
    }
  }

  .dropdown-menu {
    background-color: $white-base;

    .dropdown-divider {
      border-top-color: rgba($black-base, .22);
    }

    max-height: 392px;
    overflow-y: scroll;
  }
}

.versions {
  font-size: 1.5rem;
}

.navbar-nav {
  margin-top: 0;
}

.list-group {
  font-size: 18px; 
}

.list-group-item.active {
  background-color: #0078D7;
  
  a { 
    color: #ffffff; 
  }
}

