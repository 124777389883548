article {
  margin-bottom: 16px;
}

.ask {
  @extend .text-success;

  > p {
    text-align: center;
  }

  > button {
    display: block;
    width: 170px;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    input, textarea {
      margin-top: 16px;
    }

    .invalid-feedback {
      text-align: center;
      display: none;
    }

    .invalid-feedback.show {
      display: block;
    }
  }
}

p, dl, ol, ul, article .description {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

article {
  h2, h3, h4, h5 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.picture {
  text-align: center;
  overflow: auto;
}

.picture img {
  display: inline-block;
  margin: .5rem auto 0.5rem auto;
  width: 100%;
  height: auto;
}

.link_text {
  color: #0069ba !important;
  text-decoration: underline !important;
}

blockquote {
  text-align: center;
  border-top: solid 1px #0069ba;
  border-bottom: solid 1px #0069ba;
  padding: 10px;
  color: #0069ba !important;
  font-variant: small-caps;
  font-weight: bold;
}

.important {
  padding: 8px;
  background-color: #d6f0e0;
  // color: #0d6832;
  border-radius: 8px;
}

.danger {
  padding: 8px;
  background-color: #f9e1e5;
  // color: #af233a;
  border-radius: 8px;
}

table.tips, table.tips_blank {
  margin: 8px;
}

table.tips, .tips th, .tips td {
  border: 1px solid black;
  padding: 10px;
}

table.tips_blank, .tips_blank th, .tips_blank td {
  padding: 10px;
}

p.lang {
  margin: 32px 0px 32px 0px;
}

.english {
  background-color: transparent;
  background-image: url("/images/english.png");
  background-size: auto;
  background-repeat: no-repeat;
  padding: 10px 0px 10px 50px;
  margin-left: 10px;
}

.french {
  background-color: transparent;
  background-image: url("/images/french.png");
  background-size: auto;
  background-repeat: no-repeat;
  padding: 10px 0px 10px 50px;
  margin-left: 10px;
}

.icon-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;

  &> li {
    display: block;
    padding-left: 36px;
    text-indent: -36px;

    &.keyboard {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath d='M1783 384q28 0 53 11.5t44 31 29.5 45.5 10.5 56v864q0 30-10.5 56.5t-29.5 46-44 30.5-53 11H137q-29 0-53.5-11T40 1494.5t-29.5-46T0 1392V528q0-30 10.5-56T40 426.5t43.5-31T137 384h1646zm9 144q0-8-3.5-12t-5.5-4H137q-2 0-5.5 4t-3.5 12v864q0 8 3.5 12t5.5 4h1646q2 0 5.5-4t3.5-12V528zM256 768h256v128H256V768zm1152 0h256v128h-256V768zm0 256h256v128h-256v-128zm-1152 0h256v128H256v-128zm384 0h640v128H640v-128zm128-128H640V768h128v128zm256 0H896V768h128v128zm128-128h128v128h-128V768z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 32px 32px;
      padding-left: 72px;
    }

    &.mouse {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath d='M1184 0q100 0 187.5 37.5T1524 140t102.5 152.5T1664 480v864q0 97-25 187t-71 168.5-110 142.5-142.5 110-168.5 71-187 25-187-25-168.5-71T462 1842t-110-142.5-71-168.5-25-187V480q0-100 37.5-187.5T396 140 548.5 37.5 736 0h448zm352 480q0-73-27.5-137T1433 231t-112-75.5-137-27.5h-160v640H896V128H736q-73 0-137 27.5T487 231t-75.5 112T384 480v864q0 119 45.5 224T553 1751t183 123.5 224 45.5 224-45.5 183-123.5 123.5-183 45.5-224V480z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 32px 32px;
      padding-left: 72px;
    }

    & * {
      text-indent: initial;
    }
  }
}
