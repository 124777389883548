html {
  height: 100%;
}

body {
  height: 100%;
}

.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.layout > footer {
  margin-top: auto !important;
  background: #f2f2f2;
  color: #000;
}

div.central {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 8px;
  padding-bottom: 16px;
  width: 1302px;
}

aside {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
}

aside.left {
  margin-right: 16px;
  position: absolute;
  top: 8px;
  bottom: 16px;
}

main {
  width: 970px;
  max-width: 970px;
  margin-left: 332px;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  font-size: 18px;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  article {
    position: relative;
  }
}

footer {
  font-size: 18px;

  .container {
    width: 100%;
    max-width: 1302px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .copyright {
    padding: 16px 0;
  }

  ul.social, ul.legal {
    @extend .nav;

    li {
      @extend .nav-item;

      a {
        @extend .nav-link;
        padding: 8px;
      }
    }
  }
}

@media (max-width: 1280px) {
  div.central {
    max-width: 1232px;
    width: 100%;
  }

  main {
    width: calc(100% - 332px);
  }
}

@media (max-width: 1024px) {
  aside {
    display: none;
  }

  div.central {
    width: 100%;
  }

  main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
  }

  footer {
    .container {
      flex-direction: column;
      align-items: center;
      padding-left: 8px;
      padding-right: 8px;
    }

    .social,.legal {
      padding: 0;
      margin: 0;
    }

    .copyright {
      padding: 0;
    }
  }
}

.contact-form {
  input {
    margin-top: 16px;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.advanced-search-query > .form-control{
  flex: 1;
}
