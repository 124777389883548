.product, .related {
  padding: 0.5rem 0 0.5rem 0;
}

.article {
  padding: 0.5rem 0 0.5rem 0;
  display: flex;

  > .thumbnail {
    outline: #e0e0e0 solid 2px;
    margin-top: 0.35rem;
    width: 256px;
    height: 160px;
  }

  > .summary {
    margin-left: 16px;
  }
}

@media (max-width: 768px) {
  .article {
    flex-wrap: wrap;

    > .thumbnail {
      width: 100%;
      height: auto;
      margin: 0 0 16px 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    > .summary {
      margin: 0;

      h3 {
        display: none;
      }
    }
  }
}

.more-tips {
  text-align: center;
}

.pagination {
  justify-content: center;
}


.mi {
  display: inline-block;
}

.mi-ChevronLeft, .mi-ChevronLeftSmall, .mi-ChevronRight, .mi-ChevronRightSmall {
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
}

.mi-ChevronRight  {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath fill='%230063B1' d='M515 1955l930-931L515 93l90-90 1022 1021L605 2045z'/%3E%3C/svg%3E");
}

.mi-ChevronRightSmall {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath fill='%230063B1' d='M590 7l1017 1017L590 2041l-241-242 775-775-775-775L590 7z'/%3E%3C/svg%3E");
}

.mi-ChevronLeft {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath fill='%230063B1' d='M1443 2045L421 1024 1443 3l90 90-930 931 930 931z'/%3E%3C/svg%3E");
}

.mi-ChevronLeftSmall {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2048 2048'%3E%3Cpath fill='%230063B1' d='M1699 249l-775 775 775 775-241 242L441 1024 1458 7l241 242z'/%3E%3C/svg%3E");
}
